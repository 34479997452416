<template>
  <div class="create-venue-page">
    <Title class="mx-auto">Register the venue</Title>
    <VenueForm
      :value="venue"
      submitButtonText="Register"
      :isSubmitting="isSubmitting"
      :isIdEditable="true"
      @submit="save"
    />
  </div>
</template>

<script>
import VenueForm from "@/components/venues/VenueForm";
import { mapActions } from "vuex";
import { NEW_VENUE_MOCK } from "@/helpers/mocks";
import { sleep } from "@/helpers/utils";
import alert from "@/plugins/alert";
import { onboardBankAccountPathName } from "@/helpers/onboard";

export default {
  name: "CreateVenuePage",
  components: { VenueForm },
  data() {
    return {
      isSubmitting: false,
      venue: { ...NEW_VENUE_MOCK },
    };
  },
  methods: {
    ...mapActions({
      createVenue: "venues/createVenue",
      fetchVenue: "venues/fetchVenue",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.createVenue({ id, data });
        await sleep(5000);
        await this.fetchVenue();
        await this.$router.push({
          name: onboardBankAccountPathName,
        });
      } catch {
        alert.open({
          message: "An error has occurred. Probably the ID is already taken",
          variant: "danger",
        });
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-venue-page {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: $media-laptop) {
    gap: 64px;
  }
}
</style>
