import { render, staticRenderFns } from "./CreateVenuePage.vue?vue&type=template&id=8a36aba6&scoped=true&"
import script from "./CreateVenuePage.vue?vue&type=script&lang=js&"
export * from "./CreateVenuePage.vue?vue&type=script&lang=js&"
import style0 from "./CreateVenuePage.vue?vue&type=style&index=0&id=8a36aba6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a36aba6",
  null
  
)

export default component.exports